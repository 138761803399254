import React from 'react';
import styled from 'styled-components';

const Footer = () => (
    <FooterWrapper>
    </FooterWrapper>
);

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.primary};
  background-image: url("/img/crossword.png");
  padding: 24px 0;
  margin-top: 40px;
`;

export default Footer;
